/* eslint-disable global-require */
/* eslint-disable */

import { Controller } from 'stimulus';
import I18n from 'src/vendor/i18n-js.js.erb';
import flash from 'src/services/flash.service';
import HeartOnIcon from 'assets/icons/icon-sax/bold/heart.svg?raw';
import HeartOffIcon from 'assets/icons/icon-sax/linear/heart.svg?raw';
export default class extends Controller {
  static targets = [
    'link',
    'icon' // legacy
  ];

  connect() {
    this.updateUI();
  }

  updateUI() {
    if (this.isWatching) {
      this.linkTarget.title = this.linkTarget.dataset.removeLabel;

      if (this.hasIconTarget) {
        this.iconTarget.src = require('assets/images/icons/watchlist/heart.svg');
      } else {
        this.linkTarget.classList.add(this.linkTarget.dataset.onClass);
        this.linkTarget.innerHTML = HeartOnIcon;
      }
    } else {
      this.linkTarget.title = this.linkTarget.dataset.addLabel;

      if (this.hasIconTarget) {
        this.iconTarget.src = require('assets/images/icons/watchlist/heart_off.svg');
      } else {
        this.linkTarget.classList.remove(this.linkTarget.dataset.onClass);
        this.linkTarget.innerHTML = HeartOffIcon;
      }
    }

    if (this.hasIconTarget) {
      this.iconTarget.style.display = 'block';
      this.iconTarget.width = 32;
      this.iconTarget.height = 32;
    }
  }

  onLinkClick(event) {
    event.preventDefault();

    if (!this.isWatching) {
      this.add();
    } else {
      this.remove();
    }
  }

  add() {
    if (this.linkTarget.dataset.signedIn !== 'true') {
      window.location = this.linkTarget.dataset.fallbackUrl;
      return;
    }

    fetch(this.linkTarget.dataset.addUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': jQuery.rails.csrfToken(), // eslint-disable-line no-undef
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          throw response.statusText;
        }

        return response.text();
      })
      .then(() => {
        this.linkTarget.dataset.watching = true;

        this.updateUI();

        flash(
          'notice',
          I18n.t(
            `components.watchlist.this_${this.element.dataset.watchableType}_has_been_added_to_watchlist`
          )
        );
      })
      .catch((errorMessage) => {
        flash('alert', errorMessage);
      });
  }

  remove() {
    fetch(this.linkTarget.dataset.removeUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': jQuery.rails.csrfToken(), // eslint-disable-line no-undef,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          throw response.statusText;
        }

        return response.text();
      })
      .then(() => {
        this.linkTarget.dataset.watching = false;

        this.updateUI();

        flash(
          'notice',
          I18n.t(
            `components.watchlist.this_${this.element.dataset.watchableType}_has_been_removed_from_watchlist`
          )
        );
      })
      .catch((errorMessage) => {
        flash('alert', errorMessage);
      });
  }

  get isWatching() {
    return this.linkTarget.dataset.watching === 'true';
  }
}
