require('@rails/ujs').start();


import '@hotwired/turbo-rails';
import Turbolinks from 'turbolinks';
Turbolinks.start();

require('@rails/activestorage').start();

document.onreadystatechange = () => {
  switch (document.readyState) {
    case 'loading':
      break;
    case 'interactive':
      document.body.classList.add('loading');
      break;
    case 'complete':
      document.body.classList.add('loaded');
      break;
    default:
      break;
  }
};

document.addEventListener('turbolinks:load', () => {
  if (document.readyState === 'complete') {
    document.body.classList.add('loaded');
  }
});

Turbo.session.drive = false;
Turbo.config.forms.confirm = (jsonMessage, element) => {
  const dialog = document.getElementById('turbo-confirm');
  const { title, message, icon, button_label } = JSON.parse(jsonMessage);

  dialog.querySelector('.turbo-dialog--body--title').innerHTML = title;
  dialog.querySelector('.turbo-dialog--body--text').innerHTML = message;
  dialog.querySelector('.turbo-dialog--header--icon').innerHTML = icon;
  dialog.querySelector('.button--primary').innerHTML = button_label || 'Confirm';

  dialog.showModal();

  return new Promise((resolve, reject) => {
    dialog.addEventListener(
      'close',
      () => {
        resolve(dialog.returnValue === 'confirm');
      },
      { once: true }
    );
  });
};
