/* eslint no-unused-vars: 0 */

import { Controller } from 'stimulus';
import flash from 'src/services/flash.service';

export default class extends Controller {
  static targets = [
    'updateForm',
    'verificationCodeForm',
    'submitUpdateButton',
    'pinCodeInput',
  ];

  onSubmitUpdate(event) {
    event.detail.fetchResponse.response.text().then((value) => {
      const data = JSON.parse(value);
      if (data.error) {
        flash('notice', data.error);
      } else {
        this.submitUpdateButtonTarget.classList.add('d-none');
        this.updateFormTarget.classList.add('d-none');
        this.verificationCodeFormTarget.classList.remove('d-none');
      }
    });
  }

  onSubmitVerify(event) {
    event.detail.fetchResponse.response.text().then((value) => {
      const data = JSON.parse(value);

      if (data.error) {
        flash('notice', data.error);
        this.resetPinCode();
      } else {
        flash('notice', 'Mobile number has been changed.');

        // eslint-disable-next-line no-undef, no-restricted-globals
        Turbolinks.visit(location.toString());
      }
    });
  }

  resetPinCode() {
    document
      .querySelectorAll("[data-target*='pin-codes.pinCodeInput'")
      .forEach((input, index) => {
        input.value = '';

        if (index === 0) {
          input.focus();
        }
      });
  }
}
