/* eslint no-unused-vars: 0 */
import { Controller } from 'stimulus';
// import Siema from 'flickity';
import Flickity from 'flickity';

export default class extends Controller {
  static targets = ['track', 'virtualTour'];

  connect() {
    this.init();
  }

  init() {
    this.track.querySelectorAll('img.hidden').forEach(el => el.classList.remove('hidden'))
    // eslint-disable-next-line no-new
    this.slider = new Flickity(this.track, {
      ...this.options,
    });

    if (this.element.dataset.popup) {
      const imageNodes = this.track.querySelectorAll('img.gallery-image');
      const images = [...imageNodes].map((el) => ({
        src: el.dataset.src,
      }));

      this.slider.on(
        'staticClick',
        (event, _pointer, cellElement, cellIndex) => {
          if (!event.target.classList.contains('banner-overlay')) {
            return;
          }

          if (!cellElement) {
            return;
          }

          const index = this.hasVirtualTourTarget ? cellIndex - 1 : cellIndex;

          // eslint-disable-next-line no-undef
          $.magnificPopup.open(
            {
              items: images,
              gallery: {
                enabled: true,
              },
              showCloseBtn: false,
              type: 'image',
            },
            index
          );
        }
      );
    }

    this.preventCaching();
  }

  next(e) {
    e.preventDefault();
    this.slider.next();
  }

  prev(e) {
    e.preventDefault();
    this.slider.previous();
  }

  goTo(e) {
    e.preventDefault();
    // eslint-disable-next-line radix
    const index = parseInt(e.target.dataset.slideTarget);
    this.slider.select(index);
  }

  preventCaching() {
    document.addEventListener('turbolinks:before-cache', () => {
      this.slider.destroy();
    });
  }

  get options() {
    return {
      lazyload: true,
      imagesLoaded: false,
      pageDots: this.element.dataset.sliderPageDots === 'true',
      draggable: true,
      prevNextButtons: false,
      wrapAround: true,
      cellAlign: 'left',
    };
  }

  get track() {
    return this.trackTarget;
  }
}
