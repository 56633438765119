import { Controller } from 'stimulus';

import { createElement } from 'src/services/dom.service';
import flash from 'src/services/legacy/flash.service';
// import { patch } from 'src/services/api.service';

export default class extends Controller {
  success(event) {
    event.detail.fetchResponse.response.text().then((value) => {
      const {
        dialog,
        error_message: errorMessage,
        success,
      } = JSON.parse(value);

      if (success) {
        const node = createElement('div');
        node.innerHTML = dialog;
        document.querySelector('body').appendChild(node);
        flash('notice', 'date changed successfully!');
      } else {
        flash('alert', errorMessage);
      }
    });
  }
}
