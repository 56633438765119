/* eslint no-unused-vars: 0 */
/* eslint-disable */

import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';


export default class extends Controller {
  connect() {
    this.initializeChart();
  }

  initializeChart() {
    this.canvas = this.element.querySelector('.applicant--voting-progress-chart');
    this.accepted = parseInt(this.canvas.getAttribute('data-accepted')) || 0;
    this.rejected = parseInt(this.canvas.getAttribute('data-rejected')) || 0;
    this.total = parseInt(this.canvas.getAttribute('data-total')) || 0;
    this.pending = this.total - this.accepted - this.rejected;
    this.totalVotes = this.accepted + this.rejected;

    // If total is 0, show full pending circle
    const chartData = this.total === 0
      ? [0, 0, 1] // Show full pending circle when total is 0
      : [this.accepted, this.rejected, this.pending];

    this.chart = new Chart(this.canvas, {
      type: 'doughnut',
      data: {
        labels: ['Yes', 'No', 'Pending'],
        datasets: [{
          label: 'Progress',
          data: chartData,
          backgroundColor: ['#1B998B', '#E5422F', '#E9ECEF'],
          borderWidth: 0,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: true,
        devicePixelRatio: 4
      },
      plugins: [{
        id: 'centerText',
        afterDraw: (chart) => {
          const ctx = chart.ctx;
          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

          ctx.save();
          ctx.font = 'bold 16px Arial';
          ctx.fillStyle = '#000000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const percentage = this.total === 0 ? 0 : Math.round(this.totalVotes / this.total * 100);
          ctx.fillText(`${percentage}%`, centerX, centerY);
          ctx.restore();
        }
      }]
    });
  }
}
